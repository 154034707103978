import { Component, Input } from '@angular/core';
import { OrderComponent } from './order.component';
import { MatDialogRef } from '@angular/material/dialog';
import { AvailableProductsQueryResult, ProductRepository, AvailableProductsPayload, AvailableProduct } from '@shared/repositories/product.repository';


export enum OrderType
{
    Normal = 1,
    Renewal = 2
}

export interface ProductSelect
{
    orderType: OrderType;
    productType: string;
    productId: string;
    productName: string;
    productPrice: number;
    productCurrency: string;
}

@Component({
    selector: 'order-product',
    templateUrl: 'order-product.component.html',
    styles: [
        `
                    .table tbody tr small {display:none;}
                    .table tbody tr.selectedProduct td 
                    {
                        background-color: #d8e6cc !important;
                        color: #3b5524 !important;
                    }
                    .table tbody tr.selectedProduct small {
                        display:block;
                        white-space: pre-wrap;
                    }
                `
    ],
    standalone: false
})
export class OrderProductComponent
{
    @Input()
    public parent: OrderComponent;
    public result: AvailableProductsQueryResult;
    public confirmMove = false;
    public isLoading: boolean = false;
    public isEdit = false;

    constructor(
        private productService: ProductRepository,
        private dialogRef: MatDialogRef<OrderComponent>
    )
    {
    }

    public async ngOnInit(): Promise<void>
    {
        if (!this.parent.policyholder)
        {
            this.dialogRef.close();
            return;
        }

        if (this.parent.edit)
        {
            this.isEdit = this.parent.edit;
        }

        const data: AvailableProductsPayload =
        {
            userId: this.parent.policyholder.id,
        };

        this.isLoading = true;
        const response = await this.productService.getAvailableProducts(data);
        if (response.isSuccess)
        {
            this.result = response.result;
        }
        else
        {
            this.parent.message = response.message || 'An error occured';
        }

        this.isLoading = false;
    }

    public get availableProducts(): AvailableProduct[]
    {
        return this.result && this.result.availableProducts;
    }

    public get selectedProduct(): any
    {
        if (!this.parent.product) return null;
        return this.parent.product.productId;
    }

    public get products(): any[]
    {
        return this.availableProducts;
    }

    public get currentProducts(): any[]
    {
        return this.result && this.result.currentProducts;
    }

    public get canRenew(): boolean
    {
        return this.result && this.result.canRenew;
    }

    public get renewableProducts(): any[]
    {
        return this.result && this.result.renewableProducts || [];
    }

    public async selectRenewal(renewal: any): Promise<void>
    {
        const selection: ProductSelect =
        {
            orderType: OrderType.Renewal,
            productType: renewal.productType,
            productId: renewal.id,
            productName: renewal.name,
            productCurrency: renewal.currency,
            productPrice: renewal.price
        }
        this.parent.onProductSelect(selection);
    }

    public async selectProduct(product: any): Promise<void>
    {

        const selection: ProductSelect = 
        {
            orderType: OrderType.Normal,
            productType: product.productType,
            productId: product.id,
            productName: product.name,
            productCurrency: product.currency,
            productPrice: product.price
        }

        this.parent.onProductSelect(selection);

    }

    public async productNext(): Promise<void>
    {
        if (!this.selectProduct) return;
        this.parent.next();
    }

    public async productUpdate(): Promise<void>
    {
        this.parent.onConfirmSubmit();
        this.dialogRef.close();
    }
}
