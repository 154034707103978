import { ProductsQueryProductModel } from '@shared/repositories/product.repository';
import { Component, Input } from '@angular/core';
import { BaseComponent } from '@core/abstracts/base.component';

@Component({
    selector: 'product-card',
    templateUrl: './product-card.component.html',
    standalone: false
})
export class ProductCardComponent extends BaseComponent
{
    @Input() product: ProductsQueryProductModel;

    viewProduct(event: Event, product: any)
    {
    }

    constructor()
    {
        super();
    }


}
