@if(isLoading)
{
<mat-progress-bar mode="indeterminate"></mat-progress-bar>
}
@else
{

<ng-container *ngIf="!parent.message; else showWarning">

    <ng-container *ngIf="canRenew">

        <ng-container *ngIf="renewableProducts && renewableProducts.length > 0; else noProducts">

            <div class="message">
                <p>{{parent.policyholder.name}} is up for renewal. Select renewal package:</p>
            </div>

            <!-- renewProductsTable -->
            <table>

                <thead>
                    <tr>
                        <td>renewTo</td>
                        <td style="width: 100px; text-align: right;">price</td>
                    </tr>
                </thead>

                <tbody>
                    <tr [class.selectedProduct]="selectedProduct && selectedProduct == renewable.productTag"
                        *ngFor="let renewable of renewableProducts" (click)="selectRenewal(renewable)">
                        <td>{{renewable.productTag}}</td>
                        <td>{{renewable.totalPrice | currency: ' KES '}}</td>
                    </tr>
                </tbody>

            </table>

        </ng-container>


    </ng-container>

    <ng-container *ngIf="!canRenew">

        <ng-container *ngIf="products && products.length > 0; else noProducts">

            <!-- orderProductsTable-->
            <div class="responsive-table">
                <table class="table">
                    <thead>
                        <tr>
                            <td>Product</td>
                            <td>Price</td>
                        </tr>
                    </thead>

                    <tbody>
                        @for (product of products; track $index) {
                        <tr [class.selectedProduct]="selectedProduct && selectedProduct == product.id"
                            (click)="selectProduct(product)">
                            <td>{{product.name}}</td>
                            <td class="text-right">{{product.price | defaultCurrency: product.currency:'code':'1.0-0' }}
                            </td>
                        </tr>
                        }
                    </tbody>

                </table>
            </div>
        </ng-container>

    </ng-container>

    <div class="tw-flex tw-flex-row tw-justify-between tw-my-4">

        <button type="button" class="tw-btn-error" mat-dialog-close>
            Cancel
        </button>

        @if(isEdit)
        {
        <button type="button" mat-button class="tw-btn" [ngClass]="{'tw-bg-green-400': !!selectedProduct}"
            [disabled]="!selectedProduct" (click)="productUpdate()">
            Update
        </button>
        }
        @else
        {

        <button type="button" class="tw-btn" [ngClass]="{'tw-bg-brand-default': !!selectedProduct}"
            [disabled]="!selectedProduct" (click)="productNext()">
            Next
        </button>
        }

    </div>

</ng-container>

}
<ng-template #showWarning>

    <p>{{parent.message}}</p>

    <div>
        <button type="button" mat-button class="btn" mat-dialog-close>
            ok
        </button>
    </div>

</ng-template>

<ng-template #noProducts>

    <div class="tw-py-4">
        <mat-divider></mat-divider>
    </div>

    <p>There are none available products at this time</p>

</ng-template>