import { Injectable } from '@angular/core';
import { CommandService, ICommandResponse } from '@core/services/command.service';
import { QueryService, IQueryResponse } from '@core/services/query.service';

export interface IDistributionChannel 
{
    id: string;
    name: string;
    countryId: string;
}

export interface DistributionChannelsQueryPayload
{
    searchString?: string;
    sortBy?: string;
    orderBy?: string;
    skip?: number;
    take?: number;
}

export interface DistributionChannelsQueryModel 
{
    id: string;
    createdAt: Date;
    name: string;
    organization: string;
    enabled: boolean;
    numMembers: number;
    numCollectives: number;
}

export interface DistributionChannelsQueryResponse
{
    distributionChannels: DistributionChannelsQueryModel[];
    totalCount: number;
}

export interface DistributionChannelListModel 
{
    id: string;
    name: string;
    organization: string;
}

export interface DistributionChannelsListQueryResponse
{
    distributionChannels: DistributionChannelListModel[];
}

export interface DistributionChannelUser
{
    id: string
    organizationName: string
    name: string
    type: string
    memberSince: string
}

export interface DistributionChannelQueryResponse
{
    distributionChannel: DistributionChannelModel;
    geolocations: GeolocationModel[];
    payoutDistributions: PayoutDistributionModel[];
}

export interface DistributionChannelModel
{
    id: string;
    name: string;
    organizationId: string;
    organization: string;
    enabled: boolean;
}

export interface GeolocationModel
{
    id: string;
    name: string;
}

export interface ManagerModel
{
    id: string;
    name: string;
    nationalId: string;
    phoneNumber: string;
    memberSince: Date;
    cancelledAt?: Date;
}

export interface TrainerModel
{
    id: string;
    name: string;
    nationalId: string;
    phoneNumber: string;
    memberSince: Date;
    cancelledAt?: Date;
    collectives: string;
    members: string;
}

export interface PayoutDistributionModel
{
    id: string;
    createdAt: Date;
    name: string;
    description: string;
    percentage: number;
}

export interface PaymentModel
{
    id: string;
    name: string;
    discriminator: string;
    direction: PaymentProviderDirection;
    directionString?: string;
    description?: string;
    minAmount: number;
    maxAmount?: number;
    currency: string;
    properties: any;
}

export interface ProductModel
{
    id: string;
    name: string;
    description: string;
    productType: string;
    currency: string;
    price: number;
    underwriterId: string;
    underwriter: string;
}

export enum PaymentProviderDirection
{
    Inbound = 'Inbound',
    Outbound = 'Outbound',
}


export interface DistributionChannelCollectiveModel
{
    id: string;
    name: string;
    numMembers: number;
    numPolicies: number;
}

export interface DistributionChannelCollectivesQueryResponse
{
    collectives: DistributionChannelCollectiveModel[];
}

export enum DistributionChannelRole
{
    Manager,
    Trainer,
}

export interface DistributionChannelSetMemberPayload
{
    distributionChannelId: string;
    userId: string;
    role: DistributionChannelRole;
}

export interface DistributionChannelRemoveMemberPayload
{
    distributionChannelId: string;
    userId: string;
    type: string;
}

export interface DistributionChannelCreatePayload
{
    name: string;
    organizationId: string;
    geolocationId: string;
}

export interface DistributionChannelRemovePaymentPayload
{
    distributionChannelId: string;
    paymentId: string;
}

@Injectable({ providedIn: 'root' })
export class DistributionChannelRepository
{
    constructor(
        private commandService: CommandService,
        private queryService: QueryService)
    {
    }

    public async getDistributionChannels(data: DistributionChannelsQueryPayload): Promise<IQueryResponse<DistributionChannelsQueryResponse>>
    {
        return await this.queryService.queryAsync('DistributionChannelsQuery', data);
    }

    public async getDistributionChannel(distributionChannelId: string): Promise<IQueryResponse<DistributionChannelQueryResponse>>
    {
        return await this.queryService.queryAsync("DistributionChannelQuery", { distributionChannelId });
    }

    public async getDistributionChannelCollectives(distributionChannelId: string): Promise<IQueryResponse<DistributionChannelCollectivesQueryResponse>>
    {
        return await this.queryService.queryAsync<DistributionChannelCollectivesQueryResponse>('DistributionChannelCollectivesQuery', { distributionChannelId });
    }

    public async getManagers(distributionChannelId: string): Promise<IQueryResponse<ManagerModel[]>>
    {
        return await this.queryService.queryAsync<ManagerModel[]>('DistributionChannelManagersQuery', { distributionChannelId });
    }

    public async getTrainers(distributionChannelId: string): Promise<IQueryResponse<TrainerModel[]>>
    {
        return await this.queryService.queryAsync<TrainerModel[]>('DistributionChannelTrainersQuery', { distributionChannelId });
    }

    public async getPayments(distributionChannelId: string): Promise<IQueryResponse<PaymentModel[]>>
    {
        return await this.queryService.queryAsync<PaymentModel[]>('DistributionChannelPaymentsQuery', { distributionChannelId });
    }

    public async getProducts(distributionChannelId: string): Promise<IQueryResponse<ProductModel[]>>
    {
        return await this.queryService.queryAsync<ProductModel[]>('DistributionChannelProductsQuery', { distributionChannelId });
    }

    public async getAvailableProducts(distributionChannelId: string): Promise<IQueryResponse<ProductModel[]>>
    {
        return await this.queryService.queryAsync<ProductModel[]>('DistributionChannelAvailableProductsQuery', { distributionChannelId });
    }

    public async getDistributionChannelsList(): Promise<IQueryResponse<DistributionChannelsListQueryResponse>>
    {
        return this.queryService.queryAsync<DistributionChannelsListQueryResponse>('DistributionChannelsListQuery');
    }

    public create(data: DistributionChannelCreatePayload): Promise<ICommandResponse>
    {
        return this.commandService.publishAsync('DistributionChannelCreate', data);
    }

    public update(data: { name: string }): Promise<ICommandResponse>
    {
        return this.commandService.publishAsync('DistributionChannelUpdate', data);
    }

    public member(data: DistributionChannelSetMemberPayload): Promise<ICommandResponse>
    {
        return this.commandService.publishAsync('DistributionChannelSetMember', data);
    }

    public removeMember(data: DistributionChannelRemoveMemberPayload): Promise<ICommandResponse>
    {
        return this.commandService.publishAsync('DistributionChannelRemoveMember', data);
    }

    public payment(data: any): Promise<ICommandResponse>
    {
        return this.commandService.publishAsync('DistributionChannelSetPayment', data);
    }

    public removePayment(data: DistributionChannelRemovePaymentPayload): Promise<ICommandResponse>
    {
        return this.commandService.publishAsync('DistributionChannelRemovePayment', data);
    }

    public setDistributionChannelProduct(data: any): Promise<ICommandResponse>
    {
        return this.commandService.publishAsync('DistributionChannelSetProduct', data);
    }

    public removeProduct(data: any): Promise<ICommandResponse>
    {
        return this.commandService.publishAsync('DistributionChannelRemoveProduct', data);
    }
}
