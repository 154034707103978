<h1 mat-dialog-title>{{subtitle}}</h1>

<mat-dialog-content>

    @switch (currentStep) {
    @case (1) {
    <order-edit [parent]="this"></order-edit>
    }
    @case (2) {
    <order-product [parent]="this"></order-product>
    }
    @case (3) {
    <identity [countryId]="collective.countryId" [isJunior]="isForJunior" (identityEvent)="handleDependant($event)">
    </identity>
    }
    @case (4) {
    <order-confirm [parent]="this"></order-confirm>
    }

    }
    <!-- <ng-container *ngIf="currentStep == 1">
        
    </ng-container>

    <ng-container *ngIf="currentStep == 2">
        <order-product [parent]="this"></order-product>
    </ng-container>

    <ng-container *ngIf="orderType === 1 && currentStep === 3">
        <identity [countryId]="collective.countryId" (identityEvent)="handleDependant($event)"></identity>

    </ng-container>

    <ng-container *ngIf="orderType === 1 && currentStep === 4">
        <order-confirm [parent]="this"></order-confirm>
    </ng-container>

    <ng-container *ngIf="orderType !== 1 && currentStep === 3">
        <order-confirm [parent]="this"></order-confirm>
    </ng-container> -->

</mat-dialog-content>