<div class="layout-column">

    <mat-form-field>
        <mat-label>Search</mat-label>
        <input type="text" matInput [formControl]="searchInput" />
        <mat-hint>Name, National ID or Phone</mat-hint>
    </mat-form-field>

</div>

<div *ngIf="!isLoading; else loader">

    <ng-container *ngIf="searchInput && users && users.length > 0; else noResult">

        <div class="responsive-table">
            <table class="table">
                <thead>
                    <tr>
                        <td>Name</td>
                        <td>National ID</td>
                        <td>Phone number</td>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let user of users" (click)="select(user)">
                        <td>{{user.name}}</td>
                        <td>{{user.nationalId}}</td>
                        <td>{{user.phoneNumber}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </ng-container>
</div>

<ng-template #loader>
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</ng-template>


<ng-template #noResult>
    <p class="pv-15">
        No matches.
    </p>
</ng-template>