<form [formGroup]="form" novalidate (submit)="submitAsync($event)">

    <dl>

        <dt>Dependant</dt>
        <dd>{{parent.dependant.name}}</dd>

        <dt>ID Number</dt>
        <dd>{{parent.dependant.idNumber}}</dd>

        <dt>Product</dt>
        <dd>{{parent.product.productName}} ({{parent.product.productCurrency}} {{parent.product.productPrice}})</dd>

    </dl>


    <div class="tw-py-4">
        <mat-checkbox formControlName="confirmed" [checked]="true">Confirm information is correct</mat-checkbox>
    </div>

    <div class="tw-flex tw-flex-row tw-justify-between">

        <button type="button" class="tw-btn" (click)="previous()">
            Previous
        </button>

        <button type="submit" class="tw-btn" [ngClass]="{'tw-bg-brand-default': form.valid}"
            [disabled]="form.invalid || isSubmitted">
            Confirm
        </button>

    </div>

</form>