import { Injectable } from "@angular/core";
import { CommandService, ICommandResponse } from '@core/services/command.service';
import { QueryService, IQueryResponse } from '@core/services/query.service';


export interface IdentityModel
{
    idNumber: string;
    name: string;
    dateOfBirth: Date;
    userId?: string;
    phone?: string;
    skipIprs?: boolean;
    birthCertificate?: string;
}

export enum Gender
{
    Undefined = 0,
    Male = 1,
    Female = 2
}

export interface SignUpKenyaIprsPayload
{
    nationalId: string;
    phoneNumber: string;
    dateOfBirth?: Date;
    email?: string;
}

export interface SignupKenyaNoIprsPayload 
{
    firstName: string;
    familyName: string;
    gender: Gender;
    nationalId: string;
    phoneNumber?: string;
    dateOfBirth?: Date;
    email?: string;
}

export interface SignUpBurundiPayload 
{
    firstName: string;
    familyName: string;
    gender: Gender;
    nationalId: string;
    phoneNumber?: string;
    haugeCustomerId?: string;
    dateOfBirth?: Date;
}

@Injectable({ providedIn: 'root' })
export class IdentityRepository
{
    constructor(
        private queryService: QueryService,
        private commandService: CommandService
    ) { }

    public signupKenyaIprs(data: SignUpKenyaIprsPayload): Promise<ICommandResponse> 
    {
        return this.commandService.publishAsync('SignupKenyaIprs', data);
    }

    public signupKenyaNoIprs(data: SignupKenyaNoIprsPayload): Promise<ICommandResponse> 
    {
        return this.commandService.publishAsync('SignupKenyaNoIprs', data);
    }

    public signupBurundi(data: SignUpBurundiPayload): Promise<ICommandResponse> 
    {
        return this.commandService.publishAsync('SignUpBurundi', data);
    }
}
