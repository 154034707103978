import { Component, inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { BaseComponent } from '@core/abstracts/base.component';
import { KenyaCountryId } from "@shared/repositories/geolocation.repository";
import { IdentityModel } from "@shared/repositories/identity.repository";


export interface IdentityDialogData
{
    title: string;
    countryId?: string;
    create?: boolean;
}

@Component({
    templateUrl: 'identity.dialog.component.html',
    standalone: false
})
export class IdentityDialog extends BaseComponent
{
    public title: string;
    public countryId: string;
    public identity: IdentityModel;
    public message: string | undefined;
    private data: IdentityDialogData = inject(MAT_DIALOG_DATA);

    constructor(
        private dialogRef: MatDialogRef<IdentityDialog>
    )
    {
        super();
        this.title = this.data.title || 'Identity';
        this.countryId = this.data.countryId || KenyaCountryId;
    }

    public handleIdentity($event: any)
    {
        this.identity = $event;
    }

    public async submitAsync(ev: Event): Promise<IdentityModel>
    {
        ev.preventDefault();

        //this.message = undefined;
        if (!this.identity) return;

        this.dialogRef.close(this.identity);
    }
}
