import { Component, EventEmitter, Output } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { BaseComponent } from '@core/abstracts/base.component';
import { DateService } from "@shared/services/date.service";
import { UserRepository, CheckIdentityModel, CheckIdentityStatus } from "@shared/repositories/user.repository";
import { IdentityModel } from '@shared/repositories/identity.repository';


@Component({
    selector: 'identity-kenya-junior',
    templateUrl: 'identity-kenya-junior.component.html',
    standalone: false
})
export class IdentityKenyaJuniorComponent extends BaseComponent
{
    public minDate: Date;
    public maxDate: Date;
    public form: UntypedFormGroup;
    public message: string | null = null;
    public hasCheckedIprs: boolean = false;
    public isChecking: boolean = false;

    @Output()
    public identityEvent = new EventEmitter<IdentityModel>();


    constructor(
        private dateService: DateService,
        private userRepository: UserRepository)
    {
        super();

        this.minDate = this.dateService.dayjs.utc().subtract(24, 'year').toDate();
        this.maxDate = this.dateService.dayjs.utc().subtract(1, 'month').toDate();

        const pattern = /^[0-9]{4,10}$/;

        this.form = new UntypedFormGroup({
            idNumber: new UntypedFormControl(null, [Validators.required, Validators.pattern(pattern)]),
            name: new UntypedFormControl({ value: null, disabled: true }, [Validators.required]),
            dateOfBirth: new UntypedFormControl({ value: null, disabled: true }),
        });
    }

    private setupSkipIprs(): void
    {
        this.hasCheckedIprs = true;

        const nameControl = this.form.get('name');
        nameControl.enable();
        nameControl.markAsPristine();
        nameControl.markAsUntouched();

        const dobControl = this.form.get('dateOfBirth');
        dobControl.enable();
        dobControl.markAsPristine();
        dobControl.markAsUntouched();
    }

    public async submitAsync(ev: Event): Promise<void>
    {
        ev.preventDefault();
        this.isChecking = true;

        if (this.form.invalid) return;

        this.message = null;

        if (this.hasCheckedIprs)
        {
            this.identityEvent.emit({
                idNumber: this.form.value['idNumber'],
                name: this.form.value['name'],
                dateOfBirth: this.form.value['dateOfBirth'],
                birthCertificate: this.form.value['idNumber'],
            })
            return;
        }

        const idNumber = this.form.value['idNumber'].replace(/^0+/, '');
        const data: CheckIdentityModel =
        {
            idType: 2,
            idNumber: idNumber,
        };

        const response = await this.userRepository.checkIdentityAsync(data);
        const idCheck = response.result;
        switch (idCheck.status)
        {
            case CheckIdentityStatus.Exception:
                this.setupSkipIprs();
                break;
            case CheckIdentityStatus.TimeoutException:
                this.setupSkipIprs();
                break;
            case CheckIdentityStatus.NotFound:
                this.setupSkipIprs();
                break;
            case CheckIdentityStatus.FoundRemotely:
                this.identityEvent.emit({
                    idNumber: idCheck.idNumber,
                    name: idCheck.details.name,
                    dateOfBirth: new Date(idCheck.details.dateOfBirth),
                    birthCertificate: idCheck.details.idNumber,
                });
                break;
            default:
                this.message = 'The identity was not found';
                break;
        }

        this.isChecking = false;
    }
}
