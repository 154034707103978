<form [formGroup]="form" (submit)="submitAsync($event)">

    <div class="tw-flex tw-flex-col">

        <mat-form-field>
            <mat-label>Birth Certificate Number</mat-label>
            <input matInput formControlName="idNumber" />
            <mat-error>Required</mat-error>
        </mat-form-field>

        @if(hasCheckedIprs) {
        <mat-form-field>
            <mat-label>Name of Child</mat-label>
            <input matInput formControlName="name" />
            <mat-error>Required</mat-error>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Date of Birth</mat-label>
            <input formControlName="dateOfBirth" matInput readonly (focus)="picker.open()" [min]="minDate"
                [max]="maxDate" [matDatepicker]="picker" />
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker startView="multi-year" #picker></mat-datepicker>
        </mat-form-field>
        }
        @if(message) {
        <div class="message is-error mb-15">{{message}}</div>
        }
    </div>

    <div class="tw-flex tw-flex-row tw-justify-between">
        <mat-spinner [ngStyle]="{'visibility':isChecking ? 'visible' : 'hidden'}" [diameter]="24"></mat-spinner>
        <button type="submit" mat-stroked-button [ngClass]="form.invalid || isChecking ? '': ''"
            [disabled]="form.invalid || isChecking">
            Next
        </button>
    </div>

</form>