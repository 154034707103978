<header *ngIf="title && (actionLinks$ | async); let actionLinks">
    <div class="tw-flex tw-flex-row tw-items-center tw-justify-between tw-p-2">
        <div class="tw-flex tw-flex-row tw-items-center tw-justify-center">
            @if(returnUrl) {
            <button type="button" mat-icon-button (click)="onBackClick($event)">
                <mat-icon svgIcon="chevron-left"></mat-icon>
            </button>
            }
            <p class="tw-uppercase tw-text-xl">{{title}}</p>
        </div>
        <div class="hide-xs tw-mr-4">
            @for (link of actionLinks; track $index) {
            <button type="button" (click)="onClick(link)" matTooltip="{{link.label }}" mat-icon-button
                [attr.data-test]="link.label">
                <mat-icon [svgIcon]="link.icon"></mat-icon>
            </button>
            }
        </div>

        @if (actionLinks && actionLinks.length > 0) {
        <div class="hide-gt-xs">
            <button type="button" mat-icon-button [matMenuTriggerFor]="menu">
                <mat-icon svgIcon="dots-vertical"></mat-icon>
            </button>

            <mat-menu #menu>
                @for (link of actionLinks; track $index) {
                <button type="button" mat-menu-item (click)="onClick(link)">
                    <mat-icon [svgIcon]="link.icon"></mat-icon>
                    <span>{{link.label }}</span>
                </button>
                }
            </mat-menu>

        </div>
        }
    </div>
</header>
@if (tabs.length > 0) {
<div class="navbar">
    <nav mat-tab-nav-bar [tabPanel]="tabPanel">
        @for (tab of tabs; track tab) {
        <a mat-tab-link [routerLink]="tab.path" queryParamsHandling="preserve" routerLinkActive #rla="routerLinkActive"
            [active]="rla.isActive">{{tab.label}}</a>
        }
    </nav>
    <mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>
</div>
}

<div class="tw-m-4">
    <ng-content></ng-content>
</div>
