import { Component, Output, EventEmitter } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { BaseComponent } from '@core/abstracts/base.component';
import { IdentityModel } from "@shared/repositories/identity.repository";
import { UserRepository, CheckIdentityModel, CheckIdentityStatus } from "@shared/repositories/user.repository";
import { DateService } from "@shared/services/date.service";
import { phoneNumberValidator } from '@shared/validators/phoneNumberValidator';


@Component({
    selector: 'identity-kenya-iprs',
    templateUrl: 'identity-kenya-iprs.component.html',
    standalone: false
})
export class IdentityKenyaIprsComponent extends BaseComponent
{
    public minDate: Date;
    public maxDate: Date;
    public form: UntypedFormGroup;
    public message: string | null = null;
    public isChecking: boolean = false;

    @Output()
    public identityEvent = new EventEmitter<IdentityModel>();

    @Output()
    public noIprsEvent = new EventEmitter<boolean>();

    constructor(
        private dateService: DateService,
        private userRepository: UserRepository)
    {
        super();

        this.minDate = this.dateService.dayjs.utc().subtract(18, 'year').toDate();
        this.maxDate = this.dateService.dayjs.utc().toDate();

        const pattern = /^[0-9]{4,10}$/;

        this.form = new UntypedFormGroup({
            idNumber: new UntypedFormControl(null, [Validators.required, Validators.pattern(pattern)]),
            phone: new UntypedFormControl(null, [Validators.required, phoneNumberValidator]),
            addUser: new UntypedFormControl(true)
        });
    }

    public async submitAsync(ev: Event): Promise<void>
    {
        ev.preventDefault();
        this.isChecking = true;

        if (this.form.invalid) return;

        this.message = null;

        const idNumber = this.form.value['idNumber']; 
        const phone = this.formatPhoneNumber(this.form.value['phone']);

        const data: CheckIdentityModel =
        {
            idType: 1,
            idNumber: idNumber,
            phone: phone,
        };

        const response = await this.userRepository.checkIdentityAsync(data);
        const idCheck = response.result;
        switch (idCheck.status)
        {
            case CheckIdentityStatus.Exception:
                this.message = 'An error occurred. Try again or contact support';
                this.noIprsEvent.emit(true);
                break;

            case CheckIdentityStatus.TimeoutException:
                this.message = 'Could not reach national registry. Try again or contact support';
                break;
            case CheckIdentityStatus.UserRegisteredWithDifferentPhone:
                this.message = 'User is registered with a different phone number';
                break;

            case CheckIdentityStatus.PhoneNumberTaken:
                this.message = 'Phone number is already registered to someone else';
                break;

            case CheckIdentityStatus.PhoneInvalid:
                this.message = 'Phone number is of wrong format. Phone number must be a valid Safaricom number';
                break;
            case CheckIdentityStatus.FoundLocally: // Found Locally
            case CheckIdentityStatus.FoundRemotely: // Found Remotely
                this.identityEvent.emit(
                    {
                        idNumber: idCheck.idNumber,
                        name: idCheck.details.name,
                        dateOfBirth: new Date(idCheck.details.dateOfBirth),
                        userId: idCheck.details.userId,
                        phone: phone,
                    });
                break;
            default:
                this.message = 'Not found was not found';
                this.noIprsEvent.emit(true);
                break;
        }
        this.isChecking = false;
    }

    private formatPhoneNumber(phoneNumber: number): string
    {
        let phone = phoneNumber.toString()
        if (phone.startsWith('254'))
        {
            phone = `+${phone}`;
        }
        else if (phone.startsWith('0'))
        {
            // 07
            phone = phone.slice(1);
            phone = `+254${phone}`;
        }
        else if (phone.startsWith('7'))
        {
            phone = `+254${phone}`;
        }
        else if (phone.startsWith('+254'))
        {
            phone = `${phone}`;
        }

        return phone;
    }
}
