import { Injectable } from '@angular/core';
import { CommandService, ICommandResponse } from '@core/services/command.service';
import { QueryService, IQueryResponse } from '@core/services/query.service';


export interface OrganizationQueryResponse
{
    organization: OrganizationModel;
    collectives: CollectiveModel;
    roles: RoleModel[];
}

export interface OrganizationModel
{
    id: string;
    createdAt: Date;
    name: string;
    slug: string;
}

export interface CollectiveModel
{
    collectives: number;
    members: number;
}

export interface RoleModel
{
    id: string;
    createdAt: Date;
    name: string;
    numMembers: number;
}

export interface IOrganizationModel
{
    id: string;
    name: string;
    numMembers: number;
    numRoles: number;
    createdAt: Date;
}

export interface OrganizationsQueryResponse
{
    organizations: IOrganizationModel[];
    totalCount: number;
}


export interface OrganizationsFilterModel
{
    id: string;
    name: string;
}

export interface OrganizationsFilterQueryResponse
{
    organizations: OrganizationsFilterModel[];
}

@Injectable({ providedIn: 'root' })
export class OrganizationRepository
{
    constructor(
        private commandService: CommandService,
        private queryService: QueryService)
    {
    }

    public create(data: { name: string }): Promise<ICommandResponse>
    {
        return this.commandService.publishAsync('OrganizationCreate', data);
    }

    public async getOrganizations(): Promise<IQueryResponse<OrganizationsQueryResponse>>
    {
        return this.queryService.queryAsync('OrganizationsQuery');
    }

    public async getOrganization(organizationId: string): Promise<IQueryResponse<OrganizationQueryResponse>>
    {
        return this.queryService.queryAsync('OrganizationQuery', { organizationId });
    }

    public async getFilteredOrganizations(): Promise<IQueryResponse<OrganizationsFilterQueryResponse>>
    {
        return this.queryService.queryAsync('OrganizationsFilterQuery');
    }
}
