import { IllnessModel, IllnessRepository } from '@shared/repositories/illness.repository';
import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BaseComponent } from '@core/abstracts/base.component';

@Component({
    selector: 'illness',
    templateUrl: './illness.component.html',
    standalone: false
})
export class IllnessComponent extends BaseComponent
{

  public illnessControl = new FormControl();
  public illnesses: IllnessModel[];
  public selectedIllness: IllnessModel;

  @Output()
  public illnessEvent = new EventEmitter<IllnessModel>();

  constructor(private illnessRepository: IllnessRepository) 
  {
    super();
  }

  public async ngOnInit(): Promise<void>
  {
    await this.loadAsync();
  }

  public async loadAsync(): Promise<void>
  {
    this.illnesses = await this.getIllnesses();
  }

  private async getIllnesses(): Promise<IllnessModel[]>
  {
    const response = await this.illnessRepository.getIllnesses();
    if (response && response.isSuccess)
    {
      return response.result;

    }
    return [];
  }

  public selectIllness(illness: IllnessModel): void
  {
    this.illnessEvent.emit(illness);
  }
}
