import { Component, Output, EventEmitter, Input } from "@angular/core";
import { BaseComponent } from '@core/abstracts/base.component';
import { IdentityModel } from "@shared/repositories/identity.repository";

@Component({
    selector: 'identity-kenya',
    templateUrl: 'identity-kenya.component.html',
    standalone: false
})
export class IdentityKenyaComponent extends BaseComponent
{

    @Input()
    public isJunior: boolean = false;

    public noIprs: boolean = false;

    @Output()
    public identityEvent = new EventEmitter<IdentityModel>();


    @Output()
    public noIprsEvent = new EventEmitter<boolean>();

    constructor()
    {
        super();
    }

    public handleNoIprs(event: boolean) 
    {
        this.noIprs = event;
    }

    public handleIdentity(event: IdentityModel)
    {
        this.identityEvent.emit(event);
    }
}
