import { Component, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { OrderComponent } from './order.component';

export interface EditExisingEntry
{
    title: string;
    selection: string;
}

@Component({
    selector: 'order-edit',
    templateUrl: 'order-edit.component.html',
    standalone: false
})
export class OrderEditComponent
{
    @Input()
    public parent: OrderComponent;
    public isReady = false;
    public isEditable = false;
    public entries: EditExisingEntry[] = [];

    constructor(private dialogRef: MatDialogRef<OrderComponent>)
    {
    }

    public async ngOnInit(): Promise<void>
    {
        await this.loadAsync();
    }

    public async loadAsync(): Promise<void>
    {
        // let dependant = this.parent.dependants?.find(dependant => dependant.isBeneficiary);
        
        // let beneficiary = dependant ? dependant.name : 'Not assigned';
        // if(this.parent.isForJunior) 
        // {
        //     beneficiary = this.parent.policyholder.name;
        //     this.isEditable = true;
        // }

        // let beneficiaryEntry: EditExisingEntry = {
        //     title: 'Details',
        //     selection: beneficiary
        // }

        // this.entries.push(beneficiaryEntry);
        this.isReady = true;
    }


    public cancelEditingSale(): void
    {
        this.dialogRef.close();
    }

    public async editExistingSaleAsync(index: number): Promise<void>
    {
        if (index === 0) // beneficiary details
        {
            this.parent.setStep(3);
        }
    }

    public async removeExistingSaleAsync(): Promise<void>
    {
        this.parent.setRemove(true);
        this.parent.onExistingEdited();
    } 
}