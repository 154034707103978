<mat-form-field class="tw-w-full">
  <mat-label>Cause of Hospitalization</mat-label>
  <mat-select>
    @for (illness of illnesses; track illness.id) {
    <mat-option [value]="illness.name" (click)="selectIllness(illness)">
      {{illness.name}}
    </mat-option>
    }
  </mat-select>
  <mat-error>Illness must match an available options</mat-error>
</mat-form-field>