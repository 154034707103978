<mat-form-field class="tw-w-full">
  <mat-label>Hospital</mat-label>
  <input type="text" placeholder="Search" aria-label="Search" matInput [formControl]="hospitalControl"
    [matAutocomplete]="auto">
  <mat-autocomplete #auto="matAutocomplete">
    @for (hospital of hospitals; track hospital.id) {
    <mat-option [value]="hospital.name" (click)="selectHospital(hospital)">
      {{ hospital.name }}
    </mat-option>
    }
  </mat-autocomplete>
  <mat-error>Hospitals must match an available options</mat-error>
</mat-form-field>