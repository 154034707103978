<div class="product-card tw-mb-4 tw-mx-auto">
    <div class="tw-overflow-hidden tw-rounded-lg tw-shadow-lg lg:tw-flex">
        <div class="tw-flex-1 tw-px-6 tw-py-8 tw-bg-white lg:tw-p-8">
            <h3 class="tw-text-2xl tw-font-bold">{{ product.name }}</h3>
            <p class="tw-mt-4 tw-text-gray-600">{{ product.description }}</p>
        </div>
        <div class="tw-bg-gray-50 tw-text-center lg:tw-w-72">
            <span class="tw-text-lg">Price</span>
            <div class="tw-text-4xl tw-font-bold">{{ product.price | defaultCurrency: product.currency }}</div>
            <button class="tw-w-full tw-mt-4 tw-bg-blue-600 tw-text-white tw-py-2 tw-rounded-lg"
                (click)="viewProduct($event, product)">
                View Product
            </button>
        </div>
    </div>
</div>