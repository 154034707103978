import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { ActionLink, UiService } from '@core/providers/ui.service';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

export interface TabLink
{
    path: string[];
    label: string;
}

@Component({
    selector: 'ddf-template',
    templateUrl: 'ddf-template.component.html',
    standalone: false
})
export class TemplateComponent
{
    @Input() title: string = null;
    @Input() returnUrl: string = null;
    @Input() showOptions = false;
    @Input() forceReturnLink = false;
    @Input() tabs: TabLink[] = [];

    constructor(private uiService: UiService,
        private location: Location,
        private router: Router) { }

    public get actionLinks$(): Observable<ActionLink[]> 
    {
        return this.uiService.actionLinks$;
    }

    public onClick(actionLink: ActionLink): Promise<any>
    {
        return actionLink.onClick();
    }

    public onBackClick(ev: Event): Promise<boolean>
    {
        ev.preventDefault();

        if (this.returnUrl)
        {
            return this.router.navigateByUrl(this.returnUrl);
        }

        this.location.back();
        return Promise.resolve(true);
    }
}
