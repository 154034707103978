import { HospitalModel, HospitalRepository, HospitalQueryPayload } from '@shared/repositories/hospital.repository';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BaseComponent } from '@core/abstracts/base.component';
import { debounceTime, switchMap } from 'rxjs/operators';

@Component({
    selector: 'hospital',
    templateUrl: './hospital.component.html',
    standalone: false
})
export class HospitalComponent extends BaseComponent
{
    public hospitalControl = new FormControl();
    public hospitals: HospitalModel[];

    @Input()
    public geolocationId?: string;

    @Output()
    public hospitalEvent = new EventEmitter<HospitalModel>();
    
    constructor(private hospitalRepository: HospitalRepository) 
    {
        super();
        this.hospitalControl.valueChanges.pipe(
            debounceTime(1000),
            switchMap(value => this.getHospitals(value))
            ).subscribe(value =>
            {
              if (value != null)
              {
                this.hospitals = value;
              }
            });
    }

    private async getHospitals(searchString?: string): Promise<HospitalModel[]>
    {
        const data: HospitalQueryPayload = 
        {
            searchString: searchString,
            geolocationId: this.geolocationId
        }
        const response = await this.hospitalRepository.getHospitals(data);
        if(response && response.isSuccess) 
        {

        }
        // error
        return response.result.hospitals;
  }

  public selectHospital(hospital: HospitalModel): void
  {
        this.hospitalEvent.emit(hospital);
  }
}
